import React from 'react';
import logo from './logo.svg';
import './App.css';
import { MuiThemeProvider, createMuiTheme ,withStyles } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropUpSharp from '@material-ui/icons/ArrowDropUpSharp';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const theme = createMuiTheme({
  direction: "rtl",
 
});

const styles = theme => ({
  divStyle: {
    background: '#000',
margin: '5px',
fontFamily: 'Cairo',
fontSize : 20,
color:'#000',
'&:hover': {
background: '#000',
},
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
    [theme.breakpoints.up('sm')]: {
      width: 450,
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 5,
    backgroundColor: '#E9E4C8'
  },
  logo: {
    padding: 5,
  },
  divider: {
    height: 28,
    margin: 4,
  },
});





const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const options = ['البحث عن طريق الفئة','منازل', 'مؤسسات','محلات تجارية'];


function App(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [state, setState] = React.useState({
    lat: 32.367875,
        lng: 15.093149,
    locations: [
      {
        lat: 32.167875,
        lng: 15.092149,
      },
      {
        lat: 32.367875,
        lng: 15.193149,
      },
      {
        lat: 32.367375,
        lng: 15.093549,
      },
      {
        lat: 32.367875,
        lng: 15.033149,
      },
      {
        lat: 32.397875,
        lng: 15.083149,
      }
    ]
    
  });

  const handleClick = () => {
    alert(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };


  const { classes } = props;

  return (
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}> 
      <div className = "over">
      <Grid container  
      alignItems="center"
          justify="center" 
          spacing={1} 
          alignItems="flex-end"
          style={{padding: 10}}
          >
          <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup  ref={anchorRef} aria-label="split button">
          <Button   onClick={handleToggle}  style={{backgroundColor:'#E9E4C8', borderWidth:0 , fontFamily:'Cairo', fontSize: 18,width:258}}>{options[selectedIndex]}</Button>
          <Button
            style={{backgroundColor:'#E9E4C8', borderWidth:0}}
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropUpSharp />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList style={{fontFamily:'Cairo' , fontSize: 18}} id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                      style={{fontFamily:'Cairo', fontSize: 18}}
                        key={option}
                        disabled={index === 0}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
   <Paper component="form" className={classes.root}>
      <div className={classes.logo} aria-label="menu">
      <img src={require('./images/zliten.png')} style={{width:40 , height:40}} />
      </div>
      <InputBase
      style={{ WebkitAppearance: 'none'}}
        className={classes.input}
        placeholder="البحث عن متجر"
        inputProps={{
          style: {fontSize: 25 , fontFamily: 'Cairo' }  
        }}
        InputLabelProps={{style: {fontSize: 25 ,fontFamily: 'Cairo' }}}
      />
      <Button type="submit" className={classes.iconButton} aria-label="search">
        <SearchIcon fontSize='large'/>
      </Button>

    </Paper>
    </Grid>
    </div>
    <Map google={props.google} zoom={12}
    initialCenter={{
      lat: state.lat,
      lng: state.lng
    }}
    fullscreenControl={false}
    rotateControl={false}
    panControl={false}
    streetViewControl={false}
    scaleControl={false}
    zoomControl={false}
    mapTypeControl={false}
    >
    { state.locations.map((location, i) => { 

    return <Marker
            name={'Current location'} 
            position={{lat: location.lat, lng: location.lng}} />
  })
}
    <InfoWindow >
        <div>
        </div>
    </InfoWindow>
  </Map>
  </MuiThemeProvider>
    </StylesProvider>  
  );
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyDFdoN0NF6ue3dLmPRE8Id8XMVCZ1Xr434')
})(withStyles(styles)(App))
